const imageMetadata = {
  "photos/1.jpeg": {
    film: "Kodak Ektar 100",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.67599722222222, 'longitude': 16.890272222222222},
  },
  "photos/2.jpeg": {
    film: "Kodak Ektar 100",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.67744722222222, 'longitude': 16.89067777777778},
  },
  "photos/3.jpeg": {
    film: "Kodak Ektar 100",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.67774722222222, 'longitude': 16.89328611111111},
  },
  "photos/4.jpeg": {
    film: "Kodak Ektar 100",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.65091111111111, 'longitude': 16.897388888888887},
  },
  "photos/5.jpeg": {
    film: "Kodak Portra 160",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: null,
  },
  "photos/6.jpeg": {
    film: "Kodak Portra 160",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.636830555555555, 'longitude': 16.940047222222223},
  },
  "photos/7.jpeg": {
    film: "Kodak Portra 800",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.65091111111111, 'longitude': 16.897388888888887},
  },
  "photos/8.jpeg": {
    film: "Kodak Portra 800",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: null,
  },
  "photos/9.jpeg": {
    film: "Kodak Portra 800",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.65079444444444, 'longitude': 16.89665833333333},
  },
  "photos/10.jpeg": {
    film: "Kodak Portra 160",
    camera: "Minolta Panorama Zoom 28",
    lens: null,
    location: {'latitude': 52.366791666666664, 'longitude': 4.907997222222223},
  },
  "photos/11.jpeg": {
    film: "Kodak Portra 160",
    camera: "Minolta Panorama Zoom 28",
    lens: null,
    location: {'latitude': 52.23332777777778, 'longitude': 5.9457611111111115},
  },
  "photos/12.jpeg": {
    film: "Kodak Gold 200",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 52.23770277777778, 'longitude': 5.9460500000000005},
  },
  "photos/13.jpeg": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.735508333333335, 'longitude': 16.928611111111113},
  },
  "photos/14.jpeg": {
    film: "Kodak Ultramax 400",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 44.83615833333334, 'longitude': 0.5660861111111112},
  },
  "photos/15.jpeg": {
    film: "Kodak Portra 400",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 44.82143888888889, 'longitude': 0.5589944444444445},
  },
  "photos/16.jpeg": {
    film: "Kodak Portra 400",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 44.82416666666667, 'longitude': 0.5583027777777778},
  },
  "photos/17.jpeg": {
    film: "Kono Delight Art 400",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: null,
  },
  "photos/18.jpeg": {
    film: "Kono Delight Art 400",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 48.88037222222222, 'longitude': 2.355011111111111},
  },
  "photos/19.jpeg": {
    film: "Kono Delight Art 400",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 52.21931666666667, 'longitude': 5.270366666666667},
  },
  "photos/20.jpeg": {
    film: "Kodak Ektachrome E100",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 44.84821388888889, 'longitude': 0.58045},
  },
  "photos/21.jpeg": {
    film: "Lomography Tungsten x64",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 28.22435833333333, 'longitude': 16.627275},
  },
  "photos/22.jpeg": {
    film: "Kodak Gold 200",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 28.127280555555554, 'longitude': 16.78293611111111},
  },
  "photos/23.jpeg": {
    film: "Kodak Gold 200",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 28.121052777777777, 'longitude': 16.775666666666666},
  },
  "photos/24.jpeg": {
    film: "Kodak Gold 200",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 28.52146388888889, 'longitude': 16.295075},
  },
  "photos/25.jpeg": {
    film: "Kodak Gold 200",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 28.127983333333333, 'longitude': 16.78278611111111},
  },
  "photos/26.jpeg": {
    film: "CineStill 800T",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 52.383783333333334, 'longitude': 4.8953999999999995},
  },
  "photos/27.jpeg": {
    film: "CineStill 800T",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 52.383827777777775, 'longitude': 4.895144444444444},
  },
  "photos/28.jpeg": {
    film: "CineStill 800T",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 52.3768, 'longitude': 4.889913888888889},
  },
  "photos/29.jpeg": {
    film: "CineStill 800T",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 52.36712777777778, 'longitude': 4.902177777777778},
  },
  "photos/30.jpeg": {
    film: "CineStill 800T",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 52.379025, 'longitude': 4.899911111111111},
  },
  "photos/31.jpeg": {
    film: "Kodak Ektachrome E100",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 52.402113888888884, 'longitude': 5.614341666666666},
  },
  "photos/32.jpeg": {
    film: "Agfa Foto LeBox",
    camera: "Agfa Foto LeBox",
    lens: null,
    location: {'latitude': 28.424961111111113, 'longitude': 14.057577777777778},
  },
  "photos/33.jpeg": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.674213888888886, 'longitude': 16.9024},
  },
  "photos/34.jpeg": {
    film: "Kodak Portra 400",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 48.82795277777778, 'longitude': 2.3688861111111112},
  },
  "photos/35.jpeg": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.674213888888886, 'longitude': 16.9024},
  },
  "photos/36.jpeg": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.674213888888886, 'longitude': 16.9024},
  },
  "photos/37.jpeg": {
    film: "Kodak Portra 400",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 48.83316111111112, 'longitude': 2.362741666666667},
  },
  "photos/38.jpeg": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.636136111111114, 'longitude': 16.93265277777778},
  },
  "photos/39.jpeg": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.63720277777778, 'longitude': 16.931030555555555},
  },
  "photos/40.jpeg": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.635327777777775, 'longitude': 16.93995},
  },
  "photos/41.jpeg": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.63608611111111, 'longitude': 16.93131111111111},
  },
  "photos/42.jpeg": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.635327777777775, 'longitude': 16.93995},
  },
  "photos/43.jpeg": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.674213888888886, 'longitude': 16.9024},
  },
  "photos/44.jpeg": {
    film: "CineStill 800T",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 52.369011111111114, 'longitude': 4.886513888888889},
  },
  "photos/45.jpeg": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.674213888888886, 'longitude': 16.9024},
  },
  "photos/46.jpeg": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.674213888888886, 'longitude': 16.9024},
  },
  "photos/47.jpeg": {
    film: "Ilford Delta 100",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 52.786094444444444, 'longitude': 4.709305555555556},
  },
  "photos/48.jpeg": {
    film: "Ilford HP5 plus",
    camera: "Rollei 35 LED",
    lens: "40mm ƒ3.5",
    location: {'latitude': 48.84115, 'longitude': 2.320469444444444},
  },
  "photos/49.jpeg": {
    film: "Kodak Gold 200",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 52.782066666666665, 'longitude': 4.7276750000000005},
  },
  "photos/50.jpeg": {
    film: "Ilford HP5 plus",
    camera: "Rollei 35 LED",
    lens: "40mm ƒ3.5",
    location: {'latitude': 44.82143888888889, 'longitude': 0.5589944444444445},
  },
  "photos/51.jpeg": {
    film: "Ilford HP5 plus",
    camera: "Rollei 35 LED",
    lens: "40mm ƒ3.5",
    location: {'latitude': 44.824513888888895, 'longitude': 0.5575527777777778},
  },
  "photos/52.jpeg": {
    film: "CineStill 400D",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 48.88428888888889, 'longitude': 2.3413416666666667},
  },
  "photos/53.jpeg": {
    film: "CineStill 400D",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 48.85138055555556, 'longitude': 2.3268361111111107},
  },
  "photos/54.jpeg": {
    film: "CineStill 400D",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 48.81595833333333, 'longitude': 2.1077777777777778},
  },
  "photos/55.jpeg": {
    film: "Kodak Portra 400",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.635983333333336, 'longitude': 16.943963888888888},
  },
  "photos/56.jpeg": {
    film: "FujiFIlm C200",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 48.861019444444445, 'longitude': 2.335863888888889},
  },
  "photos/57.jpeg": {
    film: "Kodak Portra 400",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.635327777777775, 'longitude': 16.93995},
  },
  "photos/58.jpeg": {
    film: "FujiFilm C200",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 48.86101388888889, 'longitude': 2.3358527777777778},
  },
  "photos/59.jpeg": {
    film: "Kodak Portra 400",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.635327777777775, 'longitude': 16.93995},
  },
  "photos/60.jpeg": {
    film: "Kodak Portra 400",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.736108333333334, 'longitude': 16.92508888888889},
  },
  "photos/61.jpeg": {
    film: "Reflx Lab 400D",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: null,
  },
  "photos/62.jpeg": {
    film: "Reflx Lab 400D",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.84861388888889, 'longitude': 17.153480555555554},
  },
  "photos/63.jpeg": {
    film: "Kodak Ektar 100",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.66863333333333, 'longitude': 17.044400000000003},
  },
  "photos/64.jpeg": {
    film: "Kodak Ektar 100",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.66863333333333, 'longitude': 17.044400000000003},
  },
  "photos/65.jpeg": {
    film: "Reflx Lab 400D",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.75988888888889, 'longitude': 16.943241666666665},
  },
  "photos/66.jpeg": {
    film: "Reflx Lab 400D",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: null,
  },
  "photos/67.jpeg": {
    film: "Reflx Lab 400D",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.6429, 'longitude': 16.916313888888887},
  },
  "photos/68.jpeg": {
    film: "Reflx Lab 400D",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.64288333333333, 'longitude': 16.91612222222222},
  },
  "photos/69.jpeg": {
    film: "Reflx Lab 400D",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.64287777777778, 'longitude': 16.91616111111111},
  },
  "photos/70.jpeg": {
    film: "Kodak Ektar 100",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.63408333333333, 'longitude': 16.944197222222222},
  },
  "photos/71.jpeg": {
    film: "Kodak Portra 160",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.6333, 'longitude': 16.945308333333333},
  },
  "photos/72.jpeg": {
    film: "Kodak Portra 160",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.638558333333336, 'longitude': 16.950180555555555},
  },
  "photos/73.jpeg": {
    film: "FujiFilm C200",
    camera: "Praktica Super TL1000",
    lens: "28mm ƒ2.8",
    location: {'latitude': 48.58834743488439, 'longitude': 9.470053644563311},
  },
};

export default imageMetadata;
