import React, { useEffect, useRef, useState } from 'react';
import '../styles.css';

const ThumbnailGallery = ({ images }) => {
  const galleryRef = useRef(null);
  const [isGalleryActive, setIsGalleryActive] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const gallery = galleryRef.current;
      if (gallery) {
        const rowHeight = parseInt(window.getComputedStyle(gallery).getPropertyValue('grid-auto-rows'));
        const rowGap = parseInt(window.getComputedStyle(gallery).getPropertyValue('grid-row-gap'));

        gallery.querySelectorAll('.thumbnail-container').forEach((item) => {
          const content = item.querySelector('.thumbnail-image');
          item.style.gridRowEnd = `span ${Math.ceil((content.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap))}`;
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [images]);

  return (
    <div
      className={`thumbnail-gallery ${isGalleryActive ? 'active' : ''}`}
      ref={galleryRef}
      onMouseEnter={() => setIsGalleryActive(true)}
      onMouseLeave={() => setIsGalleryActive(false)}
    >
      {images.map((image, index) => (
        <div key={index} className="thumbnail-container">
          <img src={image} alt="" className="thumbnail-image" />
        </div>
      ))}
    </div>
  );
};

export default ThumbnailGallery;
