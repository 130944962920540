import React, { useState } from 'react';
import imageMetadata from './ImageMetadata';
import '../styles.css';

const Sidebar = ({ onNameClick, onLinkClick, currentImage }) => {
  const [showInfo, setShowInfo] = useState(true);

  const handleNameClick = () => {
    setShowInfo(!showInfo);
    if (onNameClick) {
      onNameClick();
    }
  };

  const renderImageInfo = () => {
    if (!currentImage || !imageMetadata[currentImage]) return null;

    const {film, camera, lens, location} = imageMetadata[currentImage];

    return (
      <div className="image-info-box">
        <h2>📷 Info</h2>
        {
          camera && (
            <div className="film-info">
            <p><strong>Camera:</strong><br></br>{camera}</p>
            </div>
          )
        }
        {
          lens && (
            <div className="film-info">
            <p><strong>Lens:</strong><br></br>{lens}</p>
            </div>
          )
        }
        {film && (
          <div className="film-info">
            {/* <p><strong>Film:</strong><br></br>{film}</p> */}
            <img src={`films/${film}.webp`} alt={film} className="film-image" />
          </div>
        )}
        {
        // location && (
        //   <div className="location-info">
        //     <p><strong>Location:</strong> {`Latitude: ${location.latitude}, Longitude: ${location.longitude}`}</p>
        //     {/* Embed a map preview, for example using Google Maps Static API */}
        //     <img
        //       src={`https://maps.googleapis.com/maps/api/staticmap?center=${location.latitude},${location.longitude}&zoom=12&size=200x200&key=YOUR_API_KEY`}
        //       alt="Map Preview"
        //       className="map-preview"
        //     />
        //   </div>
        // )
        }
      </div>
    );
  };

  return (
    <div className="sidebar">
      <h1 onClick={handleNameClick}>Lex Bolt</h1>
      <p><i>Shot on film</i></p>
      {showInfo && renderImageInfo()}
    </div>
  );
};

export default Sidebar;
