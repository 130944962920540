import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import ImageCarousel from './components/ImageCarousel';
import ThumbnailGallery from './components/ThumbnailGallery';
import './styles.css';

const N_IMAGES = 73;
const images = Array.from({ length: N_IMAGES }, (_, index) => `photos/${index + 1}.jpeg`).sort(() => Math.random() - 0.5);

const App = () => {
  const [showGallery, setShowGallery] = useState(false);
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const updateDarkMode = () => {
      const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      setIsDarkMode(isDarkMode);
      if (isDarkMode) {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
    };

    updateDarkMode();

    const darkModeListener = (e) => {
      updateDarkMode();
    };

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', darkModeListener);

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', darkModeListener);
    };
  }, []);

  const handleNameClick = () => {
    // console.log("name click");
    // setShowGallery(false);
  };

  const handleLinkClick = () => {
    // console.log("link click");
    // setShowGallery(true);
  };

  return (
    <div className="app">
      <Sidebar onNameClick={handleNameClick} onLinkClick={handleLinkClick} currentImage={currentImage} />
      <div className="content">
        {showGallery ? (
          <ThumbnailGallery images={images} />
        ) : (
          <ImageCarousel images={images} onImageChange={setCurrentImage} isDarkMode={isDarkMode} />
        )}
      </div>
    </div>
  );
};

export default App;
