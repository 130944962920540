import React, { useState, useRef, useEffect } from 'react';
import '../styles.css';

const ImageCarousel = ({ images, onImageChange, isDarkMode }) => {
  const carouselRef = useRef(null);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.focus();
    }
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    onImageChange(images[currentIndex]);
  }, [currentIndex, images, onImageChange]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      goToPrevious();
    } else if (event.key === 'ArrowRight') {
      goToNext();
    } else if ((event.key === 'Escape') && isFullScreen) {
      setIsFullScreen(false);
    }
  };

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseClick = () => {
    setIsFullScreen(false);
  };

  const handleBlur = () => {
    if (carouselRef.current) {
      carouselRef.current.focus();
    }
  };

  return (
    <div className="carousel-container">
      <div
        className="carousel"
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        tabIndex="0"
        ref={carouselRef}
        style={{ outline: 'none' }}
      >
        {!isFullScreen && (
          <>
            <button className={`carousel-button left ${isDarkMode ? 'dark-mode' : ''}`} onClick={goToPrevious}>&lt;</button>
            <img
              src={images[currentIndex]}
              alt=""
              className={`carousel-image ${isDarkMode ? 'dark-mode' : ''}`}
              onClick={handleImageClick}
            />
            <button className={`carousel-button right ${isDarkMode ? 'dark-mode' : ''}`} onClick={goToNext}>&gt;</button>
          </>
        )}

        {isFullScreen && (
          <div className={`full-screen-overlay ${isDarkMode ? 'dark-mode' : ''}`} onKeyDown={handleKeyDown} tabIndex="0">
            <button className={`close-button ${isDarkMode ? 'dark-mode' : ''}`} onClick={handleCloseClick}>X</button>
            <button className={`carousel-button left ${isDarkMode ? 'dark-mode' : ''}`} onClick={goToPrevious}>&lt;</button>
            <img src={images[currentIndex]} alt="" className={`full-screen-image ${isDarkMode ? 'dark-mode' : ''}`} />
            <button className={`carousel-button right ${isDarkMode ? 'dark-mode' : ''}`} onClick={goToNext}>&gt;</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageCarousel;
